import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                /* eslint-disable */
                fmq_black: "#343a40",
                fmq_gray: "#6c757d",
                primary: "#000000",
                fqm_blue: "#17a2b8"
                
            },
        },
    },
});
