<template>
  <v-dialog v-model="dialog" width="auto" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center flex-column">
        <v-icon x-large :color="colorIcon" class="mb-6">{{ icon }}</v-icon>
        <div class="mb-2 text-center">{{ dialogMessage }}</div>
      </v-card-title>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="red" outlined @click="$emit('close')">Não</v-btn>
        <v-btn color="green" outlined @click="$emit('accept')">Sim</v-btn>
        <v-btn
          v-if="thirdButton"
          color="gray"
          outlined
          @click="$emit('thirdEvent')"
          >{{thirdButtonText}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlertConfirmation",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogMessage: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "mdi-alert-circle-outline",
    },
    colorIcon: {
      type: String,
      default: "red",
    },
    thirdButton: {
      type: Boolean,
      default: false,
    },
    thirdButtonText: {
      type: String,
      default: "",
    },
  },
};
</script>
